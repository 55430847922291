import React, { useRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
// @ts-ignore: No declarations...
import Instafeed from 'instafeed.js';
import { ClassNames } from '@emotion/react';
import { SubTitle } from '../Layout/Title';
import { useElementSize } from '../../services/useElementSize';
import { Container } from '../Layout/Container';
import { useEffect } from 'react';
import { fetchInstantToken } from '../../services/fetchInstantToken';

const MARGIN = 3;
const ITEMS_IN_ROW = 3;

interface InstagramFeedProps {
    instantTokensUrl: string;
    title?: string;
}

function InstagramFeed(props: InstagramFeedProps) {
    const { instantTokensUrl, title } = props;

    const rootRef = useRef<HTMLDivElement>(null);
    const containerSize = useElementSize(rootRef);

    async function initializeInstafeed() {
        const accessToken = await fetchInstantToken(instantTokensUrl);
        const feed = new Instafeed({
            accessToken,
            debug: process.env.NODE_ENV === 'development',
            limit: 12,
            template:
                '<a href="{{link}}" target="_blank"><img title="{{caption}}" src="{{image}}" /></a>',
        });
        feed.run();
    }

    useEffect(() => {
        initializeInstafeed();
    }, []);

    return (
        <ErrorBoundary fallback={<div />}>
            <Container ref={rootRef}>
                {title && <SubTitle>{title}</SubTitle>}
                {containerSize && (
                    <ClassNames>
                        {({ css }) => {
                            const size =
                                containerSize.width / ITEMS_IN_ROW -
                                MARGIN * ITEMS_IN_ROW * 2;
                            return (
                                <div
                                    id="instafeed"
                                    className={css`
                                        direction: ltr;
                                        display: flex;
                                        flex-wrap: wrap;
                                        justify-content: center;

                                        & a {
                                            margin: 3px;
                                            display: flex;
                                            align-items: center;
                                        }
                                        & img {
                                            width: ${size}px;
                                            height: ${size}px;
                                            object-fit: cover;
                                            display: block;
                                        }
                                    `}
                                />
                            );
                        }}
                    </ClassNames>
                )}
            </Container>
        </ErrorBoundary>
    );
}

export { InstagramFeed };
