import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { useForm } from '@formspree/react';
import { ContactFormSiteData } from '../../types';
import { aboveMobile } from '../../services/mediaquery';

const Form = styled.form`
    font-size: 1.2rem;
    ${aboveMobile} {
        width: 500px;
    }
`;

const Fields = styled.div`
    display: flex;
    flex-direction: column;
`;

const Input = styled.input`
    padding: 10px 20px;
    font-size: inherit;
    font-family: inherit;
    color: inherit;
    border: 1px solid #cccccc;
    border-radius: 6px;
    margin: 5px 0;
    transition: outline-color 150ms linear;
    &:focus {
        outline-color: #d8d8d8;
    }
`;

const Textarea = styled(Input.withComponent('textarea'))`
    height: 200px;
`;

const SubmitButton = styled(Input.withComponent('button'))`
    display: block;
    padding: 10px 20px;
    margin: 5px auto 0 0;
    background-color: #efefef;
    transition: background-color 150ms linear;
    :disabled {
        opacity: 0.5;
    }
    :not(:disabled):hover {
        cursor: pointer;
        background-color: #c8c8c8;
    }
`;

const SuccessMessage = styled.span`
    color: #188a18;
`;

interface ContactFormProps {
    siteDate: ContactFormSiteData;
}

function ContactForm(props: ContactFormProps) {
    const { siteDate } = props;

    const formRef = useRef<HTMLFormElement>(null);
    const [state, handleSubmit] = useForm(siteDate.formspreeFormId);

    useEffect(() => {
        if (state.succeeded && formRef.current) {
            formRef.current.reset();
        }
    }, [state.succeeded]);

    return (
        <Form onSubmit={handleSubmit} ref={formRef}>
            <Fields>
                <Input
                    type="text"
                    name="name"
                    placeholder={siteDate.nameLabel}
                />
                <Input
                    type="email"
                    name="email"
                    placeholder={siteDate.emailLabel}
                />
                <Input
                    type="tel"
                    name="phone"
                    placeholder={siteDate.phoneLabel}
                />
                <Textarea name="message" placeholder={siteDate.messageLabel} />
            </Fields>
            <SubmitButton disabled={state.submitting} type="submit">
                {siteDate.submitLabel}
            </SubmitButton>

            {state.succeeded && (
                <SuccessMessage>הודעה נשלחה, אחזור אליכם בהקדם.</SuccessMessage>
            )}
        </Form>
    );
}

export default ContactForm;
