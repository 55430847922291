import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { InstagramFeed } from '../components/Contact/InstagramFeed';
import { Container } from '../components/Layout/Container';
import { Title } from '../components/Layout/Title';
import { ContactPageData, ImageWithSize, Settings } from '../types';
import ContactForm from '../components/Contact/ContactForm';
import instagramIcon from '../assets/instagram.svg';
import facebookIcon from '../assets/facebook.svg';
import whatsappIcon from '../assets/whatsapp.svg';
import { aboveMobile, belowEqualMobile } from '../services/mediaquery';

const Root = styled(Container)``;

const Content = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 25px 0;
    ${belowEqualMobile} {
        margin-top: 0;
        flex-direction: column-reverse;
    }
`;

const InspirationalQuote = styled(Title)`
    ${aboveMobile} {
        margin-top: 0;
    }
`;

const Info = styled.div`
    ${belowEqualMobile} {
        margin-bottom: 25px;
    }
    ${aboveMobile} {
        margin: 30px auto;
    }
`;

const InfoItem = styled.div`
    width: 175px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px auto;
    &:last-child {
        margin-bottom: 0;
    }
`;

const PhoneNumber = styled.div`
    font-size: 1.375rem;
    margin: 0 auto;
`;

const Icon = styled.img`
    height: 39px;
    width: 39px;
    display: block;
`;

interface ContactPageProps {
    data: {
        site: {
            siteMetadata: {
                contactPage: ContactPageData;
                settings: Settings<ImageWithSize>;
            };
        };
    };
}

function ContactPage(props: ContactPageProps) {
    const {
        data: {
            site: {
                siteMetadata: { contactPage, settings },
            },
        },
    } = props;

    const { personalInfo } = settings;

    const { title, inspirationalQuote, form, instagramFeed } = contactPage;

    return (
        <Root>
            {title && <Title>{title}</Title>}

            <Content>
                <ContactForm siteDate={form} />

                <Info>
                    <InspirationalQuote>
                        {inspirationalQuote}
                    </InspirationalQuote>
                    <InfoItem>
                        <PhoneNumber>{personalInfo.phoneNumber}</PhoneNumber>
                    </InfoItem>
                    <InfoItem>
                        <a href={personalInfo.instagramUrl} target="_blank">
                            <Icon src={instagramIcon} alt="instagram" />
                        </a>
                        <a href={personalInfo.facebookUrl} target="_blank">
                            <Icon src={facebookIcon} alt="facebook" />
                        </a>
                        <a href={personalInfo.whatsappUrl} target="_blank">
                            <Icon src={whatsappIcon} alt="whatsapp" />
                        </a>
                    </InfoItem>
                </Info>
            </Content>

            {instagramFeed.instantTokensUrl && (
                <InstagramFeed
                    instantTokensUrl={instagramFeed.instantTokensUrl}
                    title={instagramFeed.title}
                />
            )}
        </Root>
    );
}

export const query = graphql`
    query QueryContactPage {
        site {
            siteMetadata {
                contactPage {
                    title
                    inspirationalQuote
                    form {
                        emailLabel
                        formspreeFormId
                        messageLabel
                        nameLabel
                        submitLabel
                        phoneLabel
                    }
                    instagramFeed {
                        instantTokensUrl
                        title
                    }
                }
                settings {
                    personalInfo {
                        facebookUrl
                        instagramUrl
                        name
                        phoneNumber
                        whatsappUrl
                    }
                }
            }
        }
    }
`;

export default ContactPage;
