import { RefObject, useCallback, useEffect, useState } from 'react';
import { Size } from '../types';

function useElementSize<T extends HTMLElement = HTMLDivElement>(
    elementRef: RefObject<T>,
) {
    const [size, setSize] = useState<Size>();

    const updateSize = useCallback(() => {
        const node = elementRef?.current;
        if (node) {
            setSize({
                width: node.offsetWidth || 0,
                height: node.offsetHeight || 0,
            });
        }
    }, [elementRef]);

    useEffect(() => {
        // Add event listener
        window.addEventListener('resize', updateSize);

        // Call handler right away so state gets updated with initial window size
        updateSize();

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', updateSize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return size;
}

export { useElementSize };
