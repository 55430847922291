import axios from 'axios';

async function fetchInstantToken(instantTokensUrl: string) {
    const response = await axios.get<{ Token: string }>(instantTokensUrl);

    const token = response.data.Token;

    return token;
}

export { fetchInstantToken };
